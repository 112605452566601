import { Button, Pagination, StatInfo, Table, TimeAgo } from '@forge/common'
import { StatFieldsFragment, StatStatus } from '@forge/graphql/generated'
import classNames from 'classnames'
import { Suspense, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAdapterStatDestroy } from './useAdapterStatDestroy'
import { useAdapterStats } from './useAdapterStats'

export function AdapterStatsLog() {
  const [page, setPage] = useState(1)
  const [limit] = useState(20)

  return (
    <div className="space-y-5">
      <div className="flex items-end justify-between gap-3">
        <h1 className="text-xl">Adapter Stats Log</h1>
        <Pagination
          label={`Page ${page}`}
          disablePrev={page === 1}
          onPrevClick={() => setPage(page - 1)}
          onNextClick={() => setPage(page + 1)}
        />
      </div>
      <div className="mb-8 rounded-lg bg-white py-1 shadow">
        <Suspense
          fallback={
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header>Job</Table.Header>
                  <Table.Header align="right">Started</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {Array.from({ length: limit + 2 }, (_, index) => (
                  <Table.Row key={index}>
                    <Table.Data>
                      <div className="h-[1rem] w-72 animate-pulse rounded bg-gray-200" />
                    </Table.Data>
                    <Table.Data>
                      <div className="flex justify-end">
                        <div className="h-[1rem] w-28 animate-pulse rounded bg-gray-200" />
                      </div>
                    </Table.Data>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          }>
          <AdapterStatsTable page={page} limit={limit} />
        </Suspense>
      </div>
    </div>
  )
}

interface AdapterStatsTableProps {
  page: number
  limit: number
}

function AdapterStatsTable({ page, limit }: AdapterStatsTableProps) {
  const { adapterId } = useParams()
  const { data: { adapterStats } = {} } = useAdapterStats({
    id: String(adapterId),
    limit,
    page
  })
  const { mutate: stopStat, isLoading } = useAdapterStatDestroy()

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header>Job</Table.Header>
          <Table.Header align="right">Started</Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {adapterStats?.currentJob && (
          <Table.Row>
            <Table.Data>
              <span className="text-yellow-500">{adapterStats.currentJob}</span>
            </Table.Data>
            <Table.Data align="right">
              <Button
                size="xs"
                type="submit"
                loading={isLoading}
                variant="danger"
                onClick={() => {
                  const id = adapterStats.currentInProgress?.id
                  if (id) stopStat({ id })
                }}>
                Stop
              </Button>
            </Table.Data>
          </Table.Row>
        )}
        {adapterStats?.aggregatedStats?.map((stat) => (
          <Row key={stat.id} stat={stat} />
        ))}
      </Table.Body>
    </Table>
  )
}

interface RowProps {
  stat: StatFieldsFragment
}

function Row({ stat }: RowProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Table.Row>
      <Table.Data>
        <StatInfo
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          stat={stat}
        />
        <div className="hover:cursor-pointer" onClick={() => setIsOpen(true)}>
          <div
            className={classNames(
              'absolute top-0 left-0 h-full w-[0.3125rem]',
              {
                'bg-red-500':
                  stat.status === StatStatus.Error ||
                  stat.status === StatStatus.Stopped ||
                  stat.status === StatStatus.TooManyAttempts,
                'bg-gray-400': stat.status === StatStatus.Ready,
                'bg-green-500': stat.status === StatStatus.Success,
                'bg-yellow-500':
                  stat.status === StatStatus.Hold ||
                  stat.status === StatStatus.InProgress
              }
            )}
          />
          {stat.status === StatStatus.Stopped && (
            <div>{stat.job} has been stopped</div>
          )}
          {stat.status === StatStatus.Success && (
            <div>
              {stat.logLine || stat.job} in {stat.tookHuman || 'success'}
            </div>
          )}
          {stat.status === StatStatus.Ready && (
            <div>{stat.job} is scheduled</div>
          )}
          {/* {stat.status === 'pending' && <div>{stat.job} is working</div>} */}
          {stat.status === StatStatus.Hold && <div>{stat.job} is on hold</div>}
          {stat.status === 'too_many_attempts' && (
            <div>{stat.job} Error: Too Many Attempts</div>
          )}
          {stat.status === 'error' && <div>{stat.job} Error</div>}
          {stat.status === 'in_progress' && (
            <div>
              {stat.logLine || stat.job} in {stat.tookHuman || 'progress'}
            </div>
          )}
        </div>
      </Table.Data>
      <Table.Data align="right">
        <i className="whitespace-nowrap text-xs">
          {stat.createdAtIso ? (
            <TimeAgo date={new Date(stat.createdAtIso)} />
          ) : (
            '-'
          )}
        </i>
      </Table.Data>
    </Table.Row>
  )
}
